<template>
  <div id="footer">
    <hr />
    <section
      v-if="hasRecentlyViewedWines && showFooterBoxes && showRecentlyViewed"
      class="home-standard recently-viewed"
    >
      <div class="row">
        <div class="columns text-center">
          <h2>Recently Viewed</h2>
          <div class="carousel-container site-width">
            <div>
              <ProductGridCarousel
                carousel-id="recentlyViewedWines"
                :items="recentlyViewedWines"
                :title="null"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-show="showFooterBoxes">
      <ul class="generic-grid value">
        <li>
          <nuxt-link
            to="/wine-team/"
            @click.native="linkClicked('Wine Team Footer Click')"
          >
            <span class="title">Unrivaled Experts</span>
            <span class="description"
              >We taste over 20,000 of the world's best wines every year in
              search of those that overdeliver on price. About 1 out of 18 make
              the cut.</span
            >
            <span class="button">Meet the Team</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/perfect-provenance/"
            @click.native="linkClicked('How We Ship Footer Click')"
          >
            <span class="title">Perfect Provenance</span>
            <span class="description"
              >Climate-controlled shipping options ensure the pristine condition
              of every bottle from the winery to you. Shipping included on
              orders ${{ settingsVars.STORE_FREE_SHIPPING_DOLLARS }}+.</span
            >
            <span class="button">How We Ship</span>
          </nuxt-link>
        </li>
        <li>
          <nuxt-link
            to="/contact-us/"
            @click.native="linkClicked('Contact Us Footer Click')"
          >
            <span class="title">Here to Serve</span>
            <span class="description"
              >Our Napa-based service team can help with shipping and selecting
              wine, and if any bottle is not to your liking, we'll credit your
              order.</span
            >
            <span class="button">Contact Us</span>
          </nuxt-link>
        </li>
      </ul>
    </section>
    <footer id="footer-black">
      <div class="row">
        <div class="columns small-12 medium-6 large-3">
          <h2 class="header" role="heading" aria-level="1">Customer Support</h2>
          <nav>
            <ul>
              <li>
                <nuxt-link to="/accounts/orders/"
                  >Update an existing order</nuxt-link
                >
              </li>
              <li>
                <nuxt-link to="/accounts/profile/"
                  >Manage your account</nuxt-link
                >
              </li>
              <!--This is served by Wagtail-->
              <li><a href="/return-policy/">Return Policy</a></li>
              <li><nuxt-link to="/contact-us/">Help</nuxt-link></li>
              <li><nuxt-link to="/contact-us/">FAQ</nuxt-link></li>
            </ul>
          </nav>
          <p class="spaced">
            <span
              ><a :href="`mailto:${customerServiceEmail}`">{{
                customerServiceEmail
              }}</a></span
            >
            <span
              ><a :href="`tel:${customerServiceNumber}`">{{
                customerServiceNumberDisplay
              }}</a></span
            >
            <span>Monday - Friday</span>
            <span>7am - 5pm <em>Pacific</em></span>
          </p>
        </div>
        <div class="columns small-6 medium-6 large-3">
          <h2 class="header" role="heading" aria-level="1">Company Info</h2>
          <nav>
            <ul>
              <li><a href="/our-story/">About Us</a></li>
              <li><nuxt-link to="/where-we-ship/">Where We Ship</nuxt-link></li>
              <li>
                <nuxt-link to="/diversity/"
                  >Diversity &amp; Accessibility</nuxt-link
                >
              </li>
              <li>
                <a href="/privacy-policy/">Privacy Policy</a>
              </li>
              <li><a href="/terms/">Terms &amp; Conditions</a></li>
            </ul>
          </nav>
        </div>
        <div class="columns small-6 medium-6 large-3">
          <h2 class="header" role="heading" aria-level="1">Shop</h2>
          <nav>
            <ul>
              <li>
                <nuxt-link to="/store/is_offer/true/"
                  >Limited Time Offers</nuxt-link
                >
              </li>
              <li>
                <nuxt-link to="/store/is_offer/false/"
                  >The Store: Ships Next Day</nuxt-link
                >
              </li>
              <li>
                <nuxt-link to="/club/">Exclusive Clubs</nuxt-link>
              </li>
              <li><nuxt-link to="/giftcard/">e-Gift Cards</nuxt-link></li>
              <li v-if="!isProdEnv">
                <a
                  href="#"
                  aria-label="Only visible in QA"
                  @click.prevent="boom"
                >
                  Force 500 error
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="columns small-12 medium-6 large-3">
          <h2 class="header" role="heading" aria-level="1">Join Us</h2>
          <nav>
            <ul>
              <li v-if="podcastMetadata.show_link">
                <nuxt-link to="/podcast/">Wine Access Podcast</nuxt-link>
              </li>
              <li>
                <a href="https://discover.wineaccess.com">Articles</a>
              </li>
              <li>
                <nuxt-link to="/invite/">Refer a Friend</nuxt-link>
              </li>
              <li>
                <a href="https://discover.wineaccess.com/">Blog</a>
              </li>
            </ul>
            <ul class="social">
              <li>
                <a href="https://www.instagram.com/wineaccess/"
                  ><img
                    v-lazy-load :data-src="require('assets/images/socialicon-instagram.svg')"
                    alt="Instagram Logo"
                    role="img"
                /></a>
              </li>
              <li>
                <a href="https://www.facebook.com/hello.wine.access/"
                  ><img
                    v-lazy-load :data-src="require('assets/images/socialicon-facebook.svg')"
                    alt="Facebook Logo"
                    role="img"
                /></a>
              </li>
              <li>
                <a href="https://twitter.com/wineaccess"
                  ><img
                    v-lazy-load :data-src="require('assets/images/socialicon-twitter.svg')"
                    alt="Twitter Logo"
                    role="img"
                /></a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UCD_-xRL9gDDv79f3c4kBdFw"
                  ><img
                    v-lazy-load :data-src="require('~/assets/images/youtube.svg')"
                    alt="YouTube Logo"
                    role="img"
                /></a>
              </li>
            </ul>
            <ul class="apps">
              <li>
                <a href="https://apps.apple.com/us/app/wine-access/id1642804318"
                  ><img
                    v-lazy-load :data-src="require('~/assets/images/apple-store-badge-dark.svg')"
                    alt="Apple Store App Logo"
                    role="img"
                /></a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/apps/details?id=com.wineaccess"
                  ><img
                    v-lazy-load :data-src="require('~/assets/images/google-play-badge.png')"
                    alt="Google Play App Logo"
                    role="img"
                /></a>
              </li>
            </ul>
          </nav>
          <QuickEmailSignUp v-if="!$auth.loggedIn" />
        </div>
      </div>
      <div class="row">
        <div class="columns">
          <p class="accessibility-notice">
            Please call customer service at
            <a :href="`tel:${customerServiceNumber}`">{{
              customerServiceNumberDisplay
            }}</a>
            if you have disabilities and need assistance.
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { showPersonalized } from '~/../common/services/customerServices'
import { pushEvent } from '~/../common/utils/user'

const setFooterData = async (self) => {
  self.recentlyViewedWines = null
  if (showPersonalized(self.$auth)) {
    try {
      self.recentlyViewedWines = (
        await self.$axios.get('/api/homepage/recently-viewed-products/')
      ).data
    } catch (e) {
      self.$sentry.captureException(e)
    }
  }
}

export default {
  name: 'SiteFooter',
  components: {
    ProductGridCarousel: () =>
      import('~/components/Product/ProductGridCarousel'),
    QuickEmailSignUp: () => import('~/components/QuickEmailSignUp'),
  },
  data() {
    return {
      recentlyViewedWines: null,
      showRecentlyViewed: true,
    }
  },
  computed: {
    ...mapGetters([
      'podcastMetadata',
      'isProdEnv',
      'settingsVars',
      'showFooterBoxes',
      'customerServiceNumber',
      'customerServiceNumberDisplay',
      'customerServiceEmail',
    ]),
    loggedIn() {
      return this.$auth.loggedIn
    },
    isPersonalized() {
      return showPersonalized(this.$auth)
    },
    hasRecentlyViewedWines() {
      return !_.isEmpty(this.recentlyViewedWines)
    },
  },
  watch: {
    $route(newValue) {
      if (newValue && newValue.path) {
        this.showRecentlyViewed = !newValue.path.includes('/checkout')
      }
      setFooterData(this)
    },
    loggedIn: {
      handler(newValue, oldValue) {
        const authStateHasChanged =
          (oldValue === false && this.isPersonalized) ||
          (oldValue === true && !newValue)
        if (authStateHasChanged) {
          setFooterData(this)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    setFooterData(this)
  },
  methods: {
    boom() {
      window.scrollTo(0, 0)
      this.$nuxt.error({ statusCode: 500, message: 'Error' })
    },
    linkClicked(event) {
      pushEvent(
        this.$axios,
        {
          metric_name: event,
          url_path: this.$route.fullPath,
        },
        this.$store
      )
    },
  },
}
</script>
